import "$styles/index.scss"
import "$styles/syntax-highlighting.css"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

import "bridgetown-quick-search"

document.addEventListener('DOMContentLoaded', function() {
  const searchInput = document.querySelector('input.srch');
  console.info("Looking for search input");
  if (!searchInput) {
    console.info("No search input found");
    return;
  }
  console.info("Found search input");
  console.info("Parent element:", searchInput.parentElement);
  console.info("Parent's parent:", searchInput.parentElement?.parentElement);

  // For now, let's try inserting the button right after the input
  let clearButton = document.createElement('a');
  clearButton.className = 'btn btn-outline-secondary d-none mt-3 mb-3' ;
  clearButton.textContent = 'Clear Search';
  clearButton.href = window.location.pathname;

  // Add some margin between input and button
  clearButton.style.marginLeft = '10px';

  // Insert button after the input
  searchInput.parentElement.insertBefore(clearButton, searchInput.nextSibling);

  // Show/hide clear button based on input content
  searchInput.addEventListener('input', function() {
    console.info("Input event triggered", this.value);
    if (this.value) {
      clearButton.classList.remove('d-none');
    } else {
      clearButton.classList.add('d-none');
    }
  });
});

function handleTagVisibility() {
  // Hide all tag sections initially
  const tagSections = document.querySelectorAll('.tag-section');
  tagSections.forEach(section => {
    section.classList.add('d-none');
  });

  // Show section if it matches current hash
  const hash = window.location.hash;
  if (hash) {
    const targetSection = document.querySelector(hash);
    if (targetSection) {
      targetSection.classList.remove('d-none');
    }
  }
}

// Run on page load
document.addEventListener('DOMContentLoaded', handleTagVisibility);

// Run when hash changes (user clicks tag link)
window.addEventListener('hashchange', handleTagVisibility);


console.info("Bridgetown is loaded!")